<!-- 生态合作 -->
<template>
  <div class="cooperation wrapper pageBox">
    <!-- 顶部banner -->
    <div class="banner">
      <div class="join-btn" @click="clickBtn">
        立即加入
      </div>
      <!-- <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner1.png">
      <div class="bannerTitle">
        <img :src="bannerTitle" alt="">
        <div class="banner-info">中嘉数科与您一起，共筑未来数字生活</div>
      </div> -->
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="conBox">
        <!-- 为什么加入中嘉 -->
        <div class="why-join">
          <div class="join-title">为何加入中嘉数科生态伙伴？</div>
          <div class="join-content">
            <div class="join-item">
              <img :src="icon1" alt="">
              <div class="item-text">资源生态提供海量供需合作机会</div>
            </div>
            <div class="join-item">
              <img :src="icon2" alt="">
              <div class="item-text">数字化集成类项目强强联合</div>
            </div>
            <div class="join-item">
              <img :src="icon3" alt="">
              <div class="item-text">开源平台技术赋能研发端</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 招募对象区域 -->
      <div class="topText">
        <div class="title">招募对象</div>
      </div>
      <div class="card-box">
        <div class="card">
          <div class="card-item">
            <img :src="recruit1" alt="">
            <div>研发生产ISV生态伙伴</div>
          </div>
          <div class="card-item">
            <img :src="recruit2" alt="">
            <div>智能终端生产生态伙伴</div>
          </div>
          <div class="card-item">
            <img :src="recruit3" alt="">
            <div>系统集成商生态伙伴</div>
          </div>
          <div class="card-item">
            <img :src="recruit4" alt="">
            <div>联合体项目履约生态伙伴</div>
          </div>
        </div>
      </div>
      <!-- 加入流程区域 -->
      <div class="process-box">
        <div class="process-content">
          <div class="process-title">加入流程</div>
          <div class="item-box">
            <div class="process-item">
              <img :src="img1" alt="">
              <div class="item-title">填写基本信息</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img2" alt="">
              <div class="item-title">中嘉友好洽谈</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img3" alt="">
              <div class="item-title">签署生态合作协议</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img4" alt="">
              <div class="item-title">共同赋能未来生活</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 立即加入 -->
      <div class="now-box">
        <div class="now-content">
          <div class="now-title">立即成为精英生态伙伴，共筑未来数字生活</div>
          <div class="now-btn" @click="clickBtn">立即加入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cooperation',
  data() {
    return {
      bannerTitle: require('@/assets/img/banner-title.png'),
      icon1: require('@/assets/img/icon1.png'),
      icon2: require('@/assets/img/icon2.png'),
      icon3: require('@/assets/img/icon3.png'),
      img1: require('@/assets/img/process1.png'),
      img2: require('@/assets/img/process2.png'),
      img3: require('@/assets/img/process3.png'),
      img4: require('@/assets/img/process4.png'),
      spotsImg: require('@/assets/img/cooperation-1.png'),
      recruit1: require('@/assets/img/recruit-1.png'),
      recruit2: require('@/assets/img/recruit-2.png'),
      recruit3: require('@/assets/img/recruit-3.png'),
      recruit4: require('@/assets/img/recruit-4.png')
    };
  },
  created() {},
  mounted() {},
  methods: {
    clickBtn() {
      this.$router.push({
        path: '/cooperation/joinUs'
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.cooperation {
  .banner {
    width: 100%;
    height: 720px;
    background: url('https://static.zhongjiadata.com/images/20250227/03aeb0945c8a4e79bff3e0fd431149b3.png') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding-top: 442px;
    .join-btn {
      width: 160px;
      height: 60px;
      background: linear-gradient(180deg,#5900df 2%, #5022ff);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
    }
  }
}
.topText {
  height: auto;
  color:#333;
  line-height: 50px;
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 20px;
  .title {
    margin: 0;
  }
}
.connect {
  background-color: #f7f8fe;
  padding-top: 80px;
  .conBox {
    margin: 0 auto;
    width: 1400px;
    height: 240px;
    background: url('~@/assets/img/cooperation-1.png') no-repeat center center;
    background-size: cover;
    margin-bottom: 160px;
    .why-join {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 40px;
      .join-title {
        text-align: center;
        font-size: 36px;
        color: #333333;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 40px;
      }
      .join-content {
        display: flex;
        justify-content: space-around;
        width: 1245px;
        height: 180px;
        background: #ffffff;
        .join-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
            margin-bottom: 6px;
          }
          .item-text {
            font-size: 16px;
            text-align: center;
            color: #333;
            line-height: 22px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .card-box {
    width: 100%;
    margin-bottom: 100px;
    .card {
      margin: 0 auto;
      width: 1245px;
      display: flex;
      justify-content: space-between;
      .card-item {
        width: 297px;
        height: 200px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        div {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          height: 60px;
          background: linear-gradient(180deg,rgba(0,138,255,0.00), #008aff 50%);
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          padding-top: 28px;
        }
        &:hover {
          box-shadow: 0px 0px 10px 3px rgba(100,112,139,0.10);
        }
      }
    }
  }
  .process-box {
    width: 100%;
    height: 368px;
    background: linear-gradient(270deg,#38b9f7, #2767ff);
    position: relative;
    .process-content {
      width: 968px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      margin-top: 36px;
      .process-title {
        font-size: 36px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 40px;
        font-weight: 600;
        line-height: 50px;
      }
      .item-box {
        display: flex;
        justify-content: space-around;
        .process-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          img {
            width: 120px;
            height: 120px;
            margin-bottom: 20px;
          }
          .process-order {
            position: absolute;
            left: 0;
            top: 0;
            img {
              height: 78px;
              width: auto;
              transform: translateX(-50%);
            }
          }
          .item-title {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
          }
        }
        .process-line {
          height: 1px;
          width: 120px;
          border-top: 1px dashed #fff;
          margin-top: 152px;
        }
      }
    }
  }
  .now-box {
    width: 100%;
    height: 360px;
    position: relative;
    background: url('~@/assets/img/cooperation-1.png') no-repeat center center;
    background-size: cover;
    .now-content {
      width: 1280px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      margin-top: 95px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .now-title {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        color: #333;
        margin-bottom: 40px;
      }
      .now-btn {
        cursor: pointer;
        width: 160px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: linear-gradient(270deg,#38b9f7, #2767ff);
        border-radius: 4px;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}

</style>
